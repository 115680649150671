import { useMemo, useCallback } from 'react';

import { createPortal } from 'react-dom';
import styles from './PortalDrawer.module.css';

const PortalDrawer = ({
  children,
  overlayColor,
  placement = 'Top',
  onOutsideClick,
  zIndex = 100,
}) => {
  const drawerStyle = useMemo(() => {
    const style = {};
    style.zIndex = zIndex;

    if (overlayColor) {
      style.backgroundColor = overlayColor;
    }

    switch (placement) {
      case 'Left':
        style.alignItems = 'flex-start';
        break;
      case 'Right':
        style.alignItems = 'flex-end';
        break;
      case 'Top':
        style.alignItems = 'center';
        break;
      case 'Bottom':
        style.alignItems = 'center';
        style.justifyContent = 'flex-end';
        break;
    }

    return style;
  }, [placement, overlayColor, zIndex]);

  const onOverlayClick = useCallback(
    (e) => {
      if (
        onOutsideClick &&
        e.target.classList.contains(styles.portalPopupOverlay)
      ) {
        onOutsideClick();
      }
      e.stopPropagation();
    },
    [onOutsideClick]
  );

  return (
    <DrawerContainer>
      <div
        className={styles.portalPopupOverlay}
        style={drawerStyle}
        onClick={onOverlayClick}
      >
        {children}
      </div>
    </DrawerContainer>
  );
};

export const DrawerContainer = ({ children, containerId = 'portals' }) => {
  let portalsDiv = document.getElementById(containerId);
  if (!portalsDiv) {
    portalsDiv = document.createElement('div');
    portalsDiv.setAttribute('id', containerId);
    document.body.appendChild(portalsDiv);
  }

  return createPortal(children, portalsDiv);
};
export default PortalDrawer;
